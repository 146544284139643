<template>
  <div
    style="width: 49.5%"
    class="card card-custom gutter-b example example-compact"
  >
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">
          {{ $t('MENU.REPORT.FINANCE_REPORT.INCOMES') }}
        </h3>
      </div>
    </div>
    <div class="card-body">
      <!-- new desing -->
      <div v-for="(item, index) in getData" :key="item.id">
        <div class="card-body-hover" style="padding: 0px">
          <div
            style="border: 1px solid #e8e8e8"
            class="card card-custom gutter-b example example-compact"
          >
            <div
              class="
                card-header card-header-hover
                d-flex
                justify-content-between
              "
              v-b-toggle="'collapse-' + `${index}`"
            >
              <div class="m-0 card-ver__line-wrapper">
                <span
                  class="bullet bullet-bar align-self-stretch bg-success"
                ></span>
                <div class="card-ver__line">
                  <p class="m-0">{{ item.name }}</p>
                  <span>
                    {{ item.code }}
                  </span>
                </div>
              </div>

              <div class="m-0">
                <p class="m-0">
                  {{
                    item.total_income.total.toLocaleString('zh-ZH', {
                      style: 'currency',
                      currency: 'UZS'
                    })
                  }}
                </p>
              </div>
            </div>

            <b-collapse :id="'collapse-' + `${index}`">
              <div style="padding: 10px">
                <div class="accordion" role="tablist">
                  <div
                    v-for="catname in item.incomecategory_set"
                    :key="catname.id"
                  >
                    <b-card no-body class="mb-1">
                      <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                          v-bind="attrs"
                          v-on="on"
                          :title="catname.name"
                          class="
                            d-flex
                            align-items-center
                            justify-content-between
                            red-tooltip
                          "
                          block
                          v-b-toggle="'accordion-' + `${catname.id}`"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                class="ml-1"
                                style="margin-right: auto"
                                v-on="on"
                              >
                                {{ catname.name.slice(0, 20) }}... |
                                {{ catname.code }}
                              </span>
                            </template>
                            <span>{{ catname.name }}</span>
                          </v-tooltip>

                          <div class="mr-4" style="margin-left: auto">
                            <span>
                              {{
                                catname.total_income.total.toLocaleString(
                                  'zh-ZH',
                                  {
                                    style: 'currency',
                                    currency: 'UZS'
                                  }
                                )
                              }}
                            </span>
                          </div>
                          <b-icon
                            icon="chevron-down"
                            aria-hidden="true"
                          ></b-icon>
                        </b-button>
                      </b-card-header>
                      <b-collapse
                        v-bind:id="'accordion-' + `${catname.id}`"
                        accordion="my-accordion"
                        role="tabpanel"
                      >
                        <b-card-body>
                          <b-card-body>
                            <div>
                              <b-card-text>
                                <b-table
                                  :fields="fields"
                                  hover
                                  :items="catname.incometype_set"
                                >
                                  <template v-slot:cell(total_income)="data">
                                    <span>
                                      {{
                                        data.item.total_income.total.toLocaleString(
                                          'zh-ZH',
                                          {
                                            style: 'currency',
                                            currency: 'UZS'
                                          }
                                        )
                                      }}
                                    </span>
                                  </template>

                                  <!-- <template v-slot:cell(item)="data">
                                        <div
                                          class="categorLink"
                                          @click="categorLink"
                                        >
                                          <i
                                            class="fas fa-external-link-square-alt text-primary"
                                          ></i>
                                        </div>
                                      </template> -->
                                </b-table>
                              </b-card-text>
                            </div>
                          </b-card-body>
                        </b-card-body>
                      </b-collapse>
                    </b-card>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
      </div>
      <!-- new desing -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'name', label: '' },
        { key: 'code', label: '' },
        { key: 'total_income', label: '' }
        // { key: 'item', label: '' }
      ]
    }
  },
  async mounted() {
    await this.$store.dispatch('getMainProfitSubCategorySpecialTotal')
  },
  computed: {
    getData() {
      return this.$store.state.requests.mainProfitSubCategorySpecialTotal
    }
  }
}
</script>
<style scoped>
.categorLink {
  cursor: pointer;
}
p {
  margin-bottom: 0;
}
.collapse .card-body,
.accordion .card .card-body {
  padding: 0 !important;
}
.card-header-hover:hover {
  background-color: rgba(186, 186, 186, 0.374);
}
.card-body-hover .card-header:focus {
  outline: none;
}
.card.card-custom > .card-header {
  min-height: unset !important;
  padding: 1.5rem;
  font-weight: bold;
}
.card-ver__line-wrapper {
  position: relative;
}
.card-ver__line {
  margin-left: 10px;
}
.bullet {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
}
</style>
