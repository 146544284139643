<template>
  <div>
    <slot>
      <div class="w-100">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-body d-flex">
            <div class="col-4 pl-0">
              <v-text-field
                v-model="start_date"
                outlined
                dense
                label="Sanadan"
                append-icon="event"
                required
                v-mask="'##-##-####'"
              ></v-text-field>
            </div>
            <div class="col-4 pl-0">
              <v-text-field
                v-model="end_date"
                outlined
                dense
                label="Sanagacha"
                append-icon="event"
                required
                v-mask="'##-##-####'"
              ></v-text-field>
            </div>
            <div class="col-2">
              <v-btn class="w-100" @click="checkForDate" color="success">{{
                $t('PAGES.CHECK')
              }}</v-btn>
            </div>
            <div class="col-2 pr-0">
              <v-btn @click="cleanDate" class="w-100" color="warning">{{
                $t('BREADCRUMBS.CLEAN')
              }}</v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-start justify-content-between w-100">
        <incomesCard />
        <expensesCard />
      </div>
    </slot>
  </div>
</template>

<script>
import incomesCard from './incomesCard'
import expensesCard from './expensesCard'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

export default {
  components: {
    expensesCard,
    incomesCard
  },
  data() {
    return {
      start_date: '',
      end_date: '',
      menu2: false,
      menu3: false
    }
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.TITLE') },
      { title: this.$t('MENU.REPORT.FINANCE_REPORT.INCOMES_EXPENDITURE') }
    ])
  },
  methods: {
    cleanDate() {
      this.start_date = ''
      this.end_date = ''
    },
    checkForDate() {
      if (this.start_date !== '' && this.end_date !== '') {
        const dates = {
          start_date: this.start_date.split('-').reverse().join('-'),
          end_date: this.end_date.split('-').reverse().join('-')
        }
        console.log(dates)
      } else {
        alert('Sanalar toldirilmagan!')
      }
    },
    categorLink() {
      alert('Hi!')
    }
  }
}
</script>
